/* This example requires Tailwind CSS v2.0+ */
export default function Horario() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto text-center py-8 px-4 sm:px-6 lg:py-10 lg:px-8">
        <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
          <span className="block">Martes - Sábado</span>
          <span className="block">11:00 AM - 4:00 PM</span>
        </h2>
      </div>
    </div>
  )
}
