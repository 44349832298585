export default function MenuList({items}) {
	return (
		<ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
			{items?.map((item) => (
				<li
					key={item?.name}
					className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
				>
					<div className="flex flex-1 flex-col p-4">
						{item.img ? <img className="mx-auto h-36 w-40 flex-shrink-0 rounded-md" src={item?.img} alt="" />: ''}
						<h3 className={item.img ? "mt-3 font-medium text-gray-900" : "font-medium text-gray-900"}>{item?.name}</h3>
						<dl className="mt-1 flex flex-grow flex-col justify-between">
							<dt className="sr-only">Description</dt>
							<dd className="text-sm text-gray-500">{item?.description}</dd>
							<dt className="sr-only">Price</dt>
							<dd className="mt-3 flex-shrink-0 flex flex-wrap justify-center gap-3">
								{item?.prices.map((price) => (
									<span key={price} className="text-sm"><span className="text-rose-600 mr-1 font-semibold">{price?.name}</span>{price?.value}</span>
								))}
							</dd>
						</dl>
					</div>
				</li>
			))}
		</ul>
	)
}
