import { Route, Switch } from 'react-router-dom'
// pages
import Home from './pages/Home'
import Menu from './pages/Menu'
import Map from './pages/Map'
// Store

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/menu" component={Menu} />
      <Route exact path="/map" component={Map} />
    </Switch>
  )
}

export default Routes
