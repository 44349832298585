import React from 'react'

const Map = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 sm:px-8 pb-8 h-full">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 text-center my-4">Mapa</h2>

      <div className='w-full h-5/6 relative'>
        <iframe
          title='Pizza Express PR map'
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60573.98928217607!2d-66.08076322089842!3d18.398569800000026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa5fc06a55c986d3a!2sPizza%20Express!5e0!3m2!1sen!2sus!4v1628330862504!5m2!1sen!2sus"      
          width="100%"
          height="100%"
          frameborder="0" 
          marginheight="0" 
          marginwidth="0" 
          allowfullscreen="true"
          loading="lazy"
        />
      </div>
    </div>
  )
}

export default Map
