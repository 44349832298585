import React from 'react'
import MenuList from '../components/MenuList'
import { pizzaLegend, pizzas, pizzaExtras, acompanantes,antojos, bebidas, alcohol } from '../assets/menu'

const Menu = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 sm:px-8 pb-8">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 text-center mt-4">Menu</h2>
      {/* <p className='text-center text-sm text-gray-500 mb-4'>* Precios incluyen IVU</p> */}
      <div>
        <h3 className="text-2xl font-bold tracking-tight text-rose-600 mt- mb-2">Pizzas</h3>
        {legendItems(pizzaLegend)}
        <MenuList items={pizzas}/>
        <h4 className="text-lg font-bold tracking-tight text-rose-400 mt-4 mb-2">Extra</h4>
        <MenuList items={pizzaExtras}/>
      </div>
      <div>
        <h3 className="text-2xl font-bold tracking-tight text-rose-600 mt-8 mb-2">Acompañantes</h3>
        <MenuList items={acompanantes}/>
      </div>
      <div>
        <h3 className="text-2xl font-bold tracking-tight text-rose-600 mt-8 mb-2">Antojos</h3>
        <MenuList items={antojos}/>
      </div>
      <div>
        <h3 className="text-2xl font-bold tracking-tight text-rose-600 mt-8 mb-2">Bebidas</h3>
        <MenuList items={bebidas} />
        <h4 className="text-lg font-bold tracking-tight text-rose-400 mt-4 mb-2">Alcohol</h4>
        <MenuList items={alcohol} />
      </div>
    </div>
  )
}

function legendItems(items) {
  return (
    <div className='flex flex-wrap mb-2'>
      {items.map(item => (
        <p className='text-gray-700 mx-2'>* <span className='text-rose-600 font-bold'>{item.a}</span>{item.b} <span className="text-gray-500">{item.c}</span></p>
      ))}
    </div>
  )
}

export default Menu
