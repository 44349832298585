// Pizzas
import queso from './imgs/pizzas/queso.jpeg'
import pepperoni from './imgs/pizzas/pepperoni.jpeg'
import jamon from './imgs/pizzas/jamon.jpeg'
import chorizo from './imgs/pizzas/chorizo.jpeg'
import salchicha_italiana from './imgs/pizzas/salchicha_italiana.jpeg'
import pollo from './imgs/pizzas/pollo.jpeg'
import bacon from './imgs/pizzas/bacon.jpeg'
import meat_lovers from './imgs/pizzas/meat_lovers.jpeg'
import carbonara from './imgs/pizzas/carbonara.jpeg'
import espinaca from './imgs/pizzas/espinaca.jpg'
import tomate from './imgs/pizzas/tomate.jpeg'
import vegetales from './imgs/pizzas/vegetales.jpeg'
import ranchera from './imgs/pizzas/ranchera.jpeg'
import chachi from './imgs/pizzas/chachi.jpeg'
// Acompañantes
import ensalada from './imgs/acompanantes/ensalada.jpg'
import nuditos from './imgs/acompanantes/nuditos.jpeg'
// Antojos
import chocolate_chips from './imgs/antojos/chocolate_chips.jpeg'
import guayaba_nueces from './imgs/antojos/guayaba_nueces.jpeg'
// Bebidas
import heineken from './imgs/bebidas/heineken.jpeg'
import medalla from './imgs/bebidas/medalla.jpeg'
import parcha from './imgs/bebidas/parcha.jpeg'

export const pizzas = [
  {
    name: 'Queso',
    description: 'Salsa de tomate con especies hecha en casa con queso mozzarella',
    img: queso,
    prices: [
      {
        name: "S",
        value: "3.00"
      },
      {
        name: "P",
        value: "9.00"
      },
      {
        name: 'XL',
        value: "20.00"
      }
    ]
  },
  {
    name: 'Pepperoni',
    description: 'Salsa de tomate con especies hecha en casa con queso mozzarella y pepperonis',
    img: pepperoni,
    prices: [
      {
        name: "S",
        value: "3.00"
      },
      {
        name: "P",
        value: "11.00"
      },
      {
        name: 'XL',
        value: "24.00"
      }
    ]
  },
  {
    name: 'Jamón',
    description: 'Salsa de tomate con especies hecha en casa con queso mozzarella y jamón',
    img: jamon,
    prices: [
      {
        name: "P",
        value: "11.00"
      },
      {
        name: 'XL',
        value: "24.00"
      }
    ]
  },
  {
    name: 'Chorizo',
    description: 'Salsa de tomate con especies hecha en casa con queso mozzarella y chorizos',
    img: chorizo,
    prices: [
      {
        name: "P",
        value: "11.00"
      },
      {
        name: 'XL',
        value: "24.00"
      }
    ]
  },
  {
    name: 'Salchicha italiana',
    description: 'Salsa de tomate con especies hecha en casa con queso mozzarella y spicy italian sausage',
    img: salchicha_italiana,
    prices: [
      {
        name: "P",
        value: "11.00"
      },
      {
        name: 'XL',
        value: "24.00"
      }
    ]
  },
  {
    name: 'Bacon',
    description: 'Salsa de tomate con especies hecha en casa, queso mozzarella y bacon',
    img: bacon,
    prices: [
      {
        name: 'P',
        value: '13.00'
      },
      {
        name: 'XL',
        value: '28.00'
      }
    ]
  },
  {
    name: 'Pollo',
    description: 'Salsa de tomate con especies hecha en casa, queso mozzarella y pollo',
    img: pollo,
    prices: [
      {
        name: 'P',
        value: '13.00'
      },
      {
        name: 'XL',
        value: '28.00'
      }
    ]
  },
  {
    name: 'Meat lovers',
    description: 'Salsa de tomate con especies hecha en casa, queso mozzarella con pepperoni, chorizo, bacon, spicy italian sausage y jamón',
    img: meat_lovers,
    prices: [
      {
        name: 'P',
        value: '14.00'
      },
      {
        name: 'XL',
        value: '30.00'
      }
    ]
  },
  {
    name: 'Chicken carbonara',
    description: 'Cremosa salsa alfredo, queso mozzarella y parmesano, ajo, hierbas, bacon y pollo',
    img: carbonara,
    prices: [
      {
        name: 'P',
        value: '14.00'
      },
      {
        name: 'XL',
        value: '30.00'
      }
    ]
  },
  {
    name: 'Espinaca',
    description: 'Rica combinación de espinaca fresca, ajo tostado, salsa Alfredo, queso mozzarella y parmesano',
    img: espinaca,
    prices: [
      {
        name: 'P',
        value: '13.00'
      },
      {
        name: 'XL',
        value: '28.00'
      }
    ]
  },
  {
    name: 'La de tomates',
    description: 'Aceite de oliva con ajo, ruedas de tomate, queso mozzarella, queso parmesano y especies',
    img: tomate,
    prices: [
      {
        name: "P",
        value: "12.00"
      },
      {
        name: 'XL',
        value: "25.00"
      }
    ]
  },
  {
    name: 'Vegetales',
    description: 'Salsa de tomate con especies hecha en casa con queso mozzarella, tomate, pimientos, cebolla lila, aceitunas y setas',
    img: vegetales,
    prices: [
      {
        name: "P",
        value: "13.00"
      },
      {
        name: 'XL',
        value: "28.00"
      }
    ]
  },
  {
    name: 'La Ranchera',
    description: 'Salsa de tomate con especies hecha en casa con queso mozzarella, pollo, pepperoni, cebolla lila, jalapeños y salsa ranch.',
    img: ranchera,
    prices:[
      {
        name: 'P',
        value: '13.00'
      },
      {
        name: 'XL',
        value: '28.00'
      }
    ]
  },
  {
    name: 'La Chachi (Vegana)',
    description: 'Salsa de tomate con especies hecha en casa, espinaca fresca, tomate, pimientos, cebolla lila, aceitunas, ajo confitado y setas. Lleva aceite de ajo en el borde.',
    img: chachi,
    prices: [
      {
        name: "P",
        value: "14.00",
      },
      {
        name: "XL",
        value: "30.00"
      }
    ]
  }

]

export const pizzaExtras = [
  {
    name: 'Queso',
    prices: [
      {
        name: 'P',
        value: '1.00'
      },
      {
        name: 'XL',
        value: '2.00'
      }
    ]
  },
  {
    name: 'Salsa Alfredo',
    prices: [
      {
        name: 'P',
        value: '1.00'
      },
      {
        name: 'XL',
        value: '2.00'
      }
    ]
  },
  {
    name: 'Carne',
    prices: [
      {
        name: 'P',
        value: '1.00'
      },
      {
        name: 'XL',
        value: '2.00'
      }
    ]
  },
  {
    name: 'Pollo o Bacon',
    prices: [
      {
        name: 'P',
        value: '2.00'
      },
      {
        name: 'XL',
        value: '3.00'
      }
    ]
  },
  {
    name: 'Vegetales',
    prices: [
      {
        name: 'P',
        value: '2.00'
      },
      {
        name: 'XL',
        value: '3.00'
      }
    ]
  }
]

// export const combos = [
//   {
//     name: 'Queso',
//     description: '2 pedazos y una bebida (soda o agua)',
//     prices: [{
//       value: '5.00'
//     }]
//   },
//   {
//     name: 'Peperoni',
//     description: '2 pedazos y una bebida (soda o agua)',
//     prices: [{
//       value: '6.00'
//     }]
//   },
//   {
//     name: 'La Medalla',
//     description: '2 pedazos (queso o peperoni) y una cerveza Medalla',
//     prices: [{
//       value: '7.00'
//     }]
//   },
//   {
//     name: 'La Birra',
//     description: '2 pedazos (queso o peperoni) y cualquier cerveza',
//     prices: [{
//       value: '8.00'
//     }]
//   },
//   // {
//   //   name: 'El combotrón',
//   //   description: 'Una pizza XL de pepperoni, chorizo o queso y un six pack de cerveza coors light o natural light',
//   //   prices: [
//   //     {
//   //       value: '30.00'
//   //     }
//   //   ]
//   // },
//   {
//     name: 'Calzone',
//     description: 'Calzone de queso con selección de carnes (pepperoni, chorizo, pollo, bacon, spicy italian sausage, jamón) y una bebida (soda o agua)',
//     prices: [{ value: '15.00' }]
//   }
// ]

export const acompanantes = [
  {
    name: 'Nuditos con Ajo',
    description: `Ricos panecillos calientes con ajo y queso parmesano. Incluyen salsa marinara\nExtra mozzarella por $1.00`,
    img: nuditos,
    prices: [
      {
        name: '4x',
        value: '3.00'
      },
      {
        name: '6x',
        value: '4.00'
      },
      {
        name: '8x',
        value: '5.00'
      },
    ]
  },
  // {
  //   name: 'Ensalada',
  //   description: 'Lechuga, cebolla lila, tomates, aceitunas negras con aderezo (cesar, ranch o vinagreta de frambuesa)',
  //   img: ensalada,
  //   prices: [
  //     {
  //       name: 'pequeña',
  //       value: '5.00'
  //     },
  //     {
  //       name: 'grande',
  //       value: '9.00'
  //     }
  //   ]
  // }
]
export const antojos = [
  {
    name: 'Galletas de chocolate chip',
    description: '',
    img: chocolate_chips,
    prices: [
      {
        name: '1x',
        value: '2.00'
      },
      {
        name: '3x',
        value: '5.00'
      },
      {
        name: '12x',
        value: '20.00'
      }
    ]
  },
  {
    name: 'Galletas de guayaba y nueces',
    description: '',
    img: guayaba_nueces,
    prices: [
      {
        name: '1x',
        value: '2.00'
      },
      {
        name: '3x',
        value: '5.00'
      },
      {
        name: '12x',
        value: '20.00'
      }
    ]
  }
]

export const bebidas = [
  {
    name: 'Refrescos de lata',
    prices: [{ value: '1.00' }]
  },
  {
    name: 'Padrino',
    prices: [{ value: '2.00' }]
  },
  {
    name: 'Agua',
    prices: [{ value: '1.00' }]
  }
]
export const alcohol = [
  {
    name: 'Medalla',
    img: medalla,
    prices: [{ value: '2.00' }]
  },
  {
    name: 'Heineken',
    img: heineken,
    prices: [{ value: '3.00' }]
  },
  {
    name: 'Gasolina de parcha',
    img: parcha,
    prices: [{ value: '3.00' }]
  }
]

export const pizzaLegend = [
  {
    a: 'S',
    b: 'lice'
  },
  {
    a: 'P',
    b: 'ersonal',
    c: '(6 pedazos 10")'
  },
  {
    a: 'XL',
    b: 'arge',
    c: '(8 pedazos 18")'
  }
]