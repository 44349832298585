import React from 'react'
import AboutPannel from '../components/Home/AboutPannel'
import Horario from '../components/Home/Horario'
// import BeerPannel from '../components/Home/BeerPannel'
// import Combo from '../components/Home/Combo'
// import Mes from '../components/Home/Mes'
// import Coquito from '../components/Home/Coquito'

const Home = () => {
  return (
    <div>
      <AboutPannel />
      <Horario />
      {/* <Coquito /> */}
      {/* <Mes /> */}
      {/* <Combo /> */}
      {/* <BeerPannel /> */}
    </div>
  )
}

export default Home
