import { Fragment, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import LogoName from '../assets/logos/logo_name.png'
const navigation = {
  pages: [
    { name: 'Home', href: '/' },
    { name: 'Menu', href: '/menu' },
    { name: 'Localización', href: '/map' },
  ],
}

export default function Example() {
  const [open, setOpen] = useState(false)

  return (
    <div className="bg-white shadow">
      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 sm:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
              <div className="px-4 pt-5 pb-2 flex">
                <button
                  type="button"
                  className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Links */}
              <div className="border-t border-gray-200 py-6 flex flex-col">
                {navigation.pages.map((page) => (
                  <button className="text-left" onClick={() => setOpen(false)}>
                    <NavLink
                      key={page.name}
                      to={page.href}
                      exact
                      className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                      activeClassName="bg-rose-50 border-rose-500 text-rose-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium hover:text-rose-700 hover:bg-rose-50 hover:border-rose-500 cursor-default"
                    >
                      {page.name}
                    </NavLink>
                  </button>
                ))}
              </div>

            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      <header className="relative">
        <nav aria-label="Top">

          {/* Secondary navigation */}
          <div className="bg-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 sm:px-8">
              <div className="h-16 flex items-center justify-between">
                {/* Logo (lg+) */}
                <div className="hidden sm:flex-1 sm:flex sm:items-center">
                <img
                    src={LogoName}
                    className="hidden sm:block h-14 w-32"
                    alt="Pizzaexpress Logo"
                  />
                </div>

                <div className="hidden h-full sm:flex">
                  {/* Flyout menus */}
                  <div className="h-full flex justify-center space-x-8">
                    {navigation.pages.map((page) => (
                      <NavLink
                        key={page.name}
                        to={page.href}
                        exact
                        className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                        activeClassName="border-rose-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium hover:border-rose-500 hover:text-gray-900 cursor-default"
                      >
                        {page.name}
                      </NavLink>
                    ))}
                  </div>
                </div>

                {/* Mobile menu and search (lg-) */}
                <div className="flex-1 flex items-center sm:hidden">
                  <button
                    type="button"
                    className="-ml-2 bg-white p-2 rounded-md text-gray-400"
                    onClick={() => setOpen(true)}
                  >
                    <span className="sr-only">Open menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Logo (lg-) */}
                <div className="sm:hidden flex items-center">
                <img
                    src={LogoName}
                    className="h-14 w-32"
                    alt="PizzaExpress Logo"
                  />
                </div>

                <div className="flex-1" />

              </div>
            </div>
          </div>
        </nav>
      </header>

    </div>
  )
}

