import PizzaTomate from '../../assets/imgs/pizzas/banner.png'

export default function AboutPannel() {
  return (
    <div className="relative">
      <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
      <div className="relative shadow-xl sm:overflow-hidden">
        <div className="absolute inset-0">
          <img
            className="h-full w-full object-cover"
            src={PizzaTomate}
            alt="Pizza de tomate"
          />
          <div className="absolute inset-0 bg-gray-500 mix-blend-multiply" />
        </div>
        <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
          <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
            <span className="block text-white">Pizza artesanal</span>
            <span className="block text-rose-400">En Rio Piedras</span>
          </h1>
          <p className="mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">
            El rico sabor de nuestra pizza comienza en la masa. Lentamente fermentada hasta su punto ideal. Como dicen en italia, “digiribile”, creando una masa liviana y sabrosa.
          </p>
        </div>
      </div>
    </div>

  )
}
