import Header from './components/Header'
import Footer from './components/Footer'
import Routes from './Routes'



export default function App() {
  return (
    <div className="bg-white flex flex-col h-screen">
      <Header />
      {/* <main className="flex-grow max-w-7xl px-4 sm:px-6 sm:px-8"> */}
      <main className="flex-grow">
        <Routes />
      </main>
      <Footer />
    </div>
  )
}

